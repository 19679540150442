import React, { useEffect, useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './EventsBody.css';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import EventCard from '../EventCard/EventCard';
import axios from 'axios';
import { useTranslation } from 'react-i18next'
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import qs from 'qs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import ImageListItem from '@mui/material/ImageListItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import Slider from "react-slick";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

interface SingleObject<T> {
  id: string,
  attributes: T
}

interface Event {
  Name: string,
  Description?: string,
  DateStart?: Date,
  DateEnd?: Date,
  Attendees?: number,
  metaverse?: string,
  URL?: string,
  LinkText?: string,
  MainImageURL?: string,
  Headset?: boolean
}

function EventsBody() {
  const [eventsToday, setEventsToday] = useState<Array<SingleObject<Event>>>([]);
  const [eventsNextWeek, setEventsNextWeek] = useState<Array<SingleObject<Event>>>([]);
  const [eventsComingSoon, setEventsComingSoon] = useState<Array<SingleObject<Event>>>([]);
  const [eventSearched, setEventsSearched] = useState<Array<SingleObject<Event>>>([]);
  const [loadingToday, setLoadingToday] = useState<boolean>(true);
  const [loadingNextWeek, setLoadingNextWeek] = useState<boolean>(true);
  const [loadingComingSoon, setLoadingComingSoon] = useState<boolean>(true);
  // eslint-disable-next-line
  const [vr, setVR] = useState<boolean>(false);
  const [date, setDate] = useState<string | Date | null>();

  const [metaverse, setMetaverse] = useState<string>('');
  const [category, setCategory] = useState<string>('');

  const theme = useTheme();
  const matchesXSmall = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation('translations');
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0
        }
      },
      {
        breakpoint: 480,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          nextArrow: <></>,
          prevArrow: <></>,
        }
      }
    ]
  };

  useEffect(() => {
    // Live
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/events?populate=*&filters[Status][$eq]=LIVE`)
      .then(({ data: { data } }) => {
        setEventsToday(data);
        setLoadingToday(false);
      })

    // Coming soon
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/events?populate=*&filters[Status][$eq]=COMING_SOON`)
      .then(({ data: { data } }) => {
        setEventsComingSoon(data);
        setLoadingComingSoon(false);
      })

    // Next days
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/events?populate=*&filters[Status][$eq]=NEXT_DAYS`)
      .then(({ data: { data } }) => {
        setEventsNextWeek(data);
        setLoadingNextWeek(false);
      })
  }, [])

  const handleChangeVR = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVR(event.target.checked);
    const vrCheckedValue = String(event.target.checked)
    getFilteredResults(vrCheckedValue, 'vr')
  };


  const getFilteredResults = (value: string | Date | null | undefined, field: string) => {
    let momentDate
    if (field === 'metaverse') {
      if (value === 'ALL') setMetaverse('')
      else setMetaverse(value as string);
    }
    field === 'category' && setCategory(value as string);
    if (field === 'date') {
      momentDate = moment(value).format("YYYY-MM-DD")
      setDate(momentDate)
    }
    const query = qs.stringify({
      filters: {
        metaverse: field === 'metaverse' ? {
          $eq: value
        } : undefined,
        Category: field === 'category' ? {
          $eq: value
        } : undefined,
        DateStart: field === 'date' ? {
          $eq: momentDate
        } : undefined,
        Headset: field === 'vr' ? {
          $eq: value
        } : undefined
      },
    }, {
      encodeValuesOnly: true,
    });

    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/events?${query}`)
      .then(({ data: { data } }) => {
        setEventsSearched(data);
      })
  }

  return (
    <div >
      <Grid
        container
        justifyContent={{ xs: 'center', sm: 'space-evenly' }}
        justifyItems={{ xs: 'center', sm: 'space-evenly' }}
        alignItems={'center'}
        direction={{ xs: 'row', sm: 'row' }}
        spacing={4}
      >
        <Grid
          lg={3}
          xs={12}
          sm={12}
          item>

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              InputAdornmentProps={{
                sx: {
                  color: '#e6007a'
                }
              }}
              label={t("Date_Uppercase")}
              value={date}
              onChange={(newValue) => {
                getFilteredResults(newValue, 'date',)
              }}
              renderInput={(params) =>
                <TextField
                  sx={{
                    color: '#e6007a'
                  }}
                  fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>

        <Grid
          lg={3}
          xs={12}
          sm={12}
          item>
          <FormControl fullWidth>
            <InputLabel
              sx={{
                textTransform: 'uppercase',
                color: '#e6007a'
              }}
              id="demo-simple-select-label">{t("Metaverse")}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={metaverse}
              label={t("Metaverse")}
              onChange={(ev) => getFilteredResults(ev.target.value, 'metaverse')}
            >
              <MenuItem key={'ALL'} value={'ALL'}>All</MenuItem>
              <MenuItem key={'DECENTRALAND'} value={'DECENTRALAND'}>Decentraland</MenuItem>
              <MenuItem key={'VOXELS'} value={'VOXELS'}>Voxels</MenuItem>
              <MenuItem key={'META_FIT_XR'} value={'META_FIT_XR'}>Fit XR</MenuItem>
              <MenuItem key={'SOMNIUMSPACE'} value={'SOMNIUMSPACE'}>Somniumspace</MenuItem>
              <MenuItem key={'META_SYNTH_RIDERS'} value={'META_SYNTH_RIDERS'}>Synth Riders</MenuItem>
              <MenuItem key={'META_MULTIVERSE'} value={'META_MULTIVERSE'}>Meta Multiverse</MenuItem>
              {/* <MenuItem key={'ROBLOX'} value={'ROBLOX'}>Roblox</MenuItem> */}
              <MenuItem key={'META_ORIZON_WORLDS'} value={'META_ORIZON_WORLDS'}>Meta Horizon Worlds</MenuItem>
              <MenuItem key={'META_POKERSTARS'} value={'META_POKERSTARS'}>Meta Pokerstars</MenuItem>
              <MenuItem key={'UPLAND'} value={'UPLAND'}>Upland</MenuItem>
            </Select>
          </FormControl>

        </Grid>

        <Grid
          lg={3}
          xs={12}
          item>

          <FormControl fullWidth>
            <InputLabel
              sx={{
                textTransform: 'uppercase',
                color: '#e6007a'
              }}
              id="demo-simple-select-label">{t("Category")}</InputLabel>
            <Select
              variant='outlined'
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={category}
              label={t("Category")}
              onChange={(ev) => getFilteredResults(ev.target.value, 'category')}
            >
              <MenuItem key={'GAMING'} value={'GAMING'}>Gaming</MenuItem>
              <MenuItem key={'MUSIC'} value={'MUSIC'}>Music</MenuItem>
              <MenuItem key={'SOCIAL'} value={'SOCIAL'}>Social</MenuItem>
              <MenuItem key={'FITNESS'} value={'FITNESS'}>Fitness</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid
          lg={3}
          xs={12}
          item>
          <Grid
            container
            justifyContent={'start'}
          >
            <FormGroup>
              <FormControlLabel labelPlacement='end' control={<Switch onChange={handleChangeVR} />}
                label={<Typography color={'primary'}>VR</Typography>} />
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
      {
        eventSearched.length > 0 && (
          <Grid>
            <Typography
              sx={{
                mt: 5,
                textTransform: 'uppercase'
              }}
              color='primary'
              component={'h4'}
              variant='h4'
              gutterBottom >{t("Search result")}</Typography>
            <Grid
              sx={{
                maxWidth: matchesXSmall ? '98vw' : '100%'
              }}
            >

              <Slider {...settings}>

                {
                  eventSearched.length > 0 ? eventSearched.map(({ id, attributes: { Name,
                    DateStart,
                    DateEnd,
                    Attendees,
                    metaverse,
                    URL,
                    LinkText,
                    MainImageURL,
                    Headset
                  }
                  }, index) => (
                    <ImageListItem
                      key={index}
                      sx={{
                        width: 400,
                      }}>
                      <EventCard
                        LinkText={LinkText}
                        MainImageURL={MainImageURL}
                        metaverse={metaverse}
                        attendees={Attendees}
                        Headset={Headset}
                        URL={URL}
                        eventName={Name}
                        startDate={DateStart}
                        endDate={DateEnd} />
                    </ImageListItem>
                  )) :
                    <Typography
                      sx={{
                        textTransform: 'uppercase'
                      }}
                      color={'secondary'}>
                      {t("No events")}
                    </Typography>
                }
              </Slider>
            </Grid>
          </Grid>
        )
      }

      <Typography
        sx={{
          mt: 5,
          textTransform: 'uppercase'
        }}
        color='primary'
        component={'h4'}
        variant='h4'
        gutterBottom >{t("Today")}</Typography>

      {
        loadingToday ? (
          <Grid
            container
            flexDirection={'row'}
            justifyContent={'center'} >
            <CircularProgress
              sx={{ width: 200 }}
              size={'large'} />
          </Grid>
        ) : (
          <Grid
            sx={{
              maxWidth: matchesXSmall ? '98vw' : '100%'
            }}
          >

            <Slider {...settings}>
              {
                eventsToday.length > 0 ? eventsToday.map(({ id, attributes: { Name, DateStart, DateEnd, Attendees, metaverse, URL, LinkText, MainImageURL, Headset, Description } }, index) => (
                  <EventCard
                    key={index}
                    Description={Description}
                    LinkText={LinkText}
                    MainImageURL={MainImageURL}
                    metaverse={metaverse}
                    attendees={Attendees}
                    Headset={Headset}
                    URL={URL}
                    eventName={Name}
                    startDate={DateStart}
                    endDate={DateEnd} />
                ))
                  :
                  <Typography
                    sx={{
                      textTransform: 'uppercase'
                    }}
                    color={'secondary'}>
                    {t("No events")}
                  </Typography>
              }
            </Slider>
          </Grid>
        )
      }


      <Typography
        sx={{
          mt: 5,
          textTransform: 'uppercase'
        }}
        color='primary'
        component={'h4'}
        variant='h4'
        gutterBottom>{t("Coming Soon")}</Typography>
      {
        loadingComingSoon ? (
          <Grid
            container
            flexDirection={'row'}
            justifyContent={'center'} >
            <CircularProgress
              sx={{ width: 200 }}
              size={'large'} />
          </Grid>
        ) : (
          <Box
            sx={{
              maxWidth: matchesXSmall ? '98vw' : '100%'
            }}
          >

            <Slider {...settings}>
              {
                eventsComingSoon.length > 0 ? eventsComingSoon.map(({ id, attributes: { Name, DateStart, DateEnd, Attendees, metaverse, URL, LinkText, MainImageURL, Headset } }, index) => (
                  <EventCard
                    key={index}
                    LinkText={LinkText}
                    MainImageURL={MainImageURL}
                    metaverse={metaverse}
                    attendees={Attendees}
                    Headset={Headset}
                    URL={URL}
                    eventName={Name}
                    startDate={DateStart}
                    endDate={DateEnd} />
                ))
                  :
                  <Typography
                    sx={{
                      textTransform: 'uppercase'
                    }}
                    color={'secondary'}>
                    {t("No events")}
                  </Typography>
              }
            </Slider>
          </Box>
        )
      }
      <Typography
        sx={{
          mt: 5,
          textTransform: 'uppercase'
        }}
        color='primary'
        component={'h4'}
        variant='h4'
        gutterBottom>{t("Next days")}</Typography>
      {
        loadingNextWeek ? (
          <Grid
            container
            flexDirection={'row'}
            justifyContent={'center'} >
            <CircularProgress
              sx={{ width: 200 }}
              size={'large'} />
          </Grid>
        ) : (
          <Box
            sx={{
              maxWidth: matchesXSmall ? '98vw' : '100%',
            }} >
            <Slider {...settings}>
              {
                eventsNextWeek.length > 0 ? eventsNextWeek.map(({ id, attributes: { Name, DateStart, DateEnd, Attendees, metaverse, URL, LinkText, MainImageURL, Headset } }, index) => (
                  <EventCard
                    key={index}
                    LinkText={LinkText}
                    MainImageURL={MainImageURL}
                    metaverse={metaverse}
                    attendees={Attendees}
                    Headset={Headset}
                    URL={URL}
                    eventName={Name}
                    startDate={DateStart}
                    endDate={DateEnd} />
                ))
                  :
                  <Typography
                    sx={{
                      textTransform: 'uppercase'
                    }}
                    color={'secondary'}>
                    {t("No events")}
                  </Typography>
              }
            </Slider>
          </Box>
        )
      }
    </div >
  );
}

export default EventsBody;

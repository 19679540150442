import React from 'react';
import './NotFound.css';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';

function NotFound() {
  return (
    <Grid
      sx={{
        paddingTop: 10,
        paddingBottom: 10
      }}
      alignItems={'center'}
      direction='column'
      container >
      <InsertEmoticonIcon
        color='primary'
        sx={{
          width: 217,
          height: 238
        }}></InsertEmoticonIcon>
      <Typography
        color={'primary'}
        variant="h1"
        component="h1">
        404
      </Typography>
      <Typography
        color={'primary'}
        variant="h4"
        component="h5">
        Not Found
      </Typography>
    </Grid>
  );
}

export default NotFound;

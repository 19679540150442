import React from 'react';
import './Experiences.css';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

function Experiences() {
  return (
    <div >
      <Typography variant="h1" component="h3">
        Experiences
      </Typography>
      <Button variant="contained">Hello World</Button>
    </div>
  );
}

export default Experiences;

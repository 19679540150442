import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translations: {
        Introduction: "Introduction",
        English: "English",
        Italian: "Italian",
        Spanish: "Spanish",
        Languages: "Languages",
        "Metaverse List": "Metaverse List",
        "Follow us": "Follow us",
        "Contact us": "Contact us",
        "About": "About",
        "About us": "About us",
        "Date": "Date",
        "Date_Uppercase": "DATE",
        "Today": "Today",
        "Next days": "Next days",
        "Metaverse": "Metaverse",
        "Metaverses": "Metaverses",
        "Category": "Category",
        "Categories": "Categories",
        "Advertising": "Advertising",
        "Frequently Asked Questions": "Frequently Asked Questions",
        "Send": "Send",
        "Our Values": "Our values",
        "Until": "Until",
        "Attendees": "Attendees",
        "No events": "No events",
        "No next events": "No next events",
        "Coming Soon": "Coming soon",
        "Copyright": "All rights reserved.",
        "Search result": "Search result",
        "VR Required": "VR Required",
        "Host event": "Host event",
        "Your request": "Your request",
        "Your email": "Your email",
        "Your name company": "Your name/company",
      }
    },
    es: {
      translations: {
        Introduction: "Introduction",
        English: "Inglés",
        Italian: "Italiano",
        Spanish: "Español",
        Languages: "Idiomas",
        "Metaverse List": "Lista de metaversos",
        "Follow us": "Síguenos",
        "Contact us": "Contáctanos",
        "About": "Acerca de",
        "About us": "Nosotros",
        "Date": "Fecha",
        "Date_Uppercase": "FECHA",
        "Today": "Hoy",
        "Next days": "Próximos días",
        "Metaverse": "Metaverso",
        "Metaverses": "Metaversos",
        "Category": "Categoría",
        "Categories": "Categorías",
        "Advertising": "Publicidad",
        "Frequently Asked Questions": "Preguntas frecuentes",
        "Send": "Enviar",
        "Our Values": "Nuestros valores",
        "Until": "Hasta",
        "Attendees": "Asistentes",
        "No events": "No hay eventos",
        "No next events": "No hay eventos próximos",
        "Coming Soon": "Próximamente",
        "Copyright": "Todos los derechos reservados.",
        "Search result": "Resultados de la búsqueda",
        "VR Required": "VR requerido",
        "Host event": "Organizar evento",
        "Your request": "Tu petición",
        "Your email": "Tu correo electrónico",
        "Your name company": "Tu nombre/compañía"
      }
    },
    it: {
      translations: { 
        Introduction: "Introduction",
        English: "English",
        Italian: "Italian",
        Spanish: "Spanish",
        Languages: "Languages",
        "Metaverse List": "Metaverse List",
        "Follow us": "Follow us",
        "Contact us": "Contact us",
        "About": "About",
        "About us": "Nosotros",
        "Date": "Date",
        "Date_Uppercase": "DATE",
        "Today": "Today",
        "Next days": "Next days",
        "Metaverse": "Metaverse",
        "Metaverses": "Metaversos",
        "Category": "Category",
        "Categories": "Categories",
        "Advertising": "Advertising",
        "Frequently Asked Questions": "Frequently Asked Questions",
        "Send": "Send",
        "Our Values": "Nuestros valores",
        "Until": "Until",
        "Attendees": "Attendees",
        "No events": "No events",
        "No next events": "No next events",
        "Copyright": "Todos los derechos reservados."
      }
    },
    ger: {
      translations: {
        Introduction: "Einführung",
        "is an internationalization-framework which offers a complete solution to localize your product from web to mobile and desktop":
          "ist ein Internationalisierungs-Framework, das eine Komplettlösung für die Lokalisierung Ihres Produkts vom Web auf das Handy und den Desktop bietet",
        "Plugins to detect the user language":
          "Plugins zur Erkennung der Benutzersprache",
        "Plugins to load translations": "Plugins zum Laden von Übersetzungen",
        "Optionally cache the translations":
          "Optional die Übersetzungen zwischenspeichern",
        Advantages: "Vorteile",
        "Flexibility to use other packages":
          "Flexibilität zur Verwendung anderer Pakete"
      }
    },
    fre: {
      translations: {
        Introduction: "Introduction",
        "is an internationalization-framework which offers a complete solution to localize your product from web to mobile and desktop":
          "est un cadre d'internationalisation qui offre une solution complète pour localiser votre produit du Web au mobile et au bureau",
        "Plugins to detect the user language":
          "Plugins pour détecter la langue de l'utilisateur",
        "Plugins to load translations": "Plugins pour charger les traductions",
        "Optionally cache the translations":
          "Cachez éventuellement les traductions",
        Advantages: "Les avantages",
        "Flexibility to use other packages":
          "Flexibilité d'utiliser d'autres packages"
      }
    }
  },
  fallbackLng: "en",
  debug: false,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});

export default i18n;
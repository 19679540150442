import React, { useState } from 'react';
import './Advertising.css';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next'
import { Box, Grid, CircularProgress, Paper, TextField, Button } from '@mui/material';
import axios from 'axios';

function Advertising() {
  const { t } = useTranslation('translations');
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>();

  const send = () => {
    setLoading(true);

    const body = {
      data: {
        Name: name,
        Email: email,
        Message: message
      }
    };

    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/contact-forms`, body)
      .then(({ data: { data } }) => {
        setLoading(false);
      })
  }

  return (
    <div >
      <Box
        sx={{
          p: 2,
          marginTop: 5,
          marginBottom: 5
        }}
        component={'div'}>
        <Typography
          color='primary'
          gutterBottom
          textTransform={'uppercase'}
          variant="h4"
          component="h4">
          {t("Host event")}
        </Typography>
        <Grid
          container
          justifyContent={'center'}>
          <Grid
            item
            lg={8}
            sm={11}
            xs={10}
          >
            {
              loading ? (
                <Grid
                  container
                  flexDirection={'row'}
                  justifyContent={'center'} >
                  <CircularProgress
                    sx={{ width: 200 }}
                    size={'large'} />
                </Grid>
              ) : (
                <Paper
                  variant='outlined'
                  sx={{
                    background: 'transparent',
                    p: 5
                  }}
                >
                  <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                  >
                    <Grid
                      container
                      rowSpacing={5}
                      flexDirection={'column'}>
                      <Grid
                        lg={8}
                        item
                      >
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label={t("Your name company")}
                          onChange={e => setName(e.target.value)}
                          variant="outlined" />
                      </Grid>
                      <Grid
                        lg={8}
                        item>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          type={'email'}
                          onChange={e => setEmail(e.target.value)}
                          label={t("Your email")}
                          variant="outlined" />
                      </Grid>
                      <Grid
                        item>
                        <TextField
                          sx={{
                            ":hover": {
                              color: '#000'
                            }
                          }}
                          color={'secondary'}
                          fullWidth
                          type={'text'}
                          onChange={e => setMessage(e.target.value)}
                          multiline
                          id="outlined-basic"
                          label={t("Your request")}
                          rows={4}
                          variant="outlined" />
                      </Grid>
                      <Grid
                        item
                      >
                        <Button
                          disabled={!name || !email || !message}
                          onClick={() => send()}
                          variant='contained'>{t("Send")}</Button>
                      </Grid>
                    </Grid>

                  </Box>
                </Paper>
              )
            }


          </Grid>

        </Grid>
        <br />
        <br />
        <br />
      </Box>
    </div>
  );
}

export default Advertising;

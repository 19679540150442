import React from 'react';
import './AppFooter.css';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { ArrowDropDown } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next'
import { useMediaQuery, useTheme } from '@mui/material';

type Language = {
  code: string,
  enabled: boolean,
  name: string,
}

const languages: Array<Language> = [
  {
    name: 'English',
    code: 'en',
    enabled: true
  },
  {
    name: 'Spanish',
    code: 'es',
    enabled: true
  }
]

function AppFooter() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t, i18n } = useTranslation('translations');
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.only('md'));

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setLanguage = (selectedLanguage: string) => {
    i18n.changeLanguage(selectedLanguage)
    handleClose();
  }

  return (
    <Grid
      className='footer'
      direction={'column'}
      container>
      <Grid
        spacing={2}
        justifyContent={{ xs: 'center', sm: 'center', md: 'space-evenly', lg: 'space-evenly' }}
        alignContent={{ xs: 'center', sm: 'center' }}
        alignItems={{ xs: 'center', sm: 'center', md: 'space-evenly', lg: 'space-evenly' }}
        direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }}
        container>
        <Grid
          item >
          <Link className='App-link' to="/">
            <img
              style={{
                width: 200
              }}
              src="./assets/default-monochrome-white.svg" alt="" />
          </Link>
        </Grid>

        <Grid
          item >
          <Link
            className='App-link'
            to="/about">
            <Typography
              sx={{
                textTransform: 'uppercase'
              }}
              color={'secondary'}
              variant="h6"
              component="h6">
              {t("About")}
            </Typography>
          </Link>
        </Grid>
        {
          !matches && (
            <Grid
              item>
              <Link className='App-link' to="/list">
                <Typography
                  color={'secondary'}
                  sx={{
                    textTransform: 'uppercase'
                  }}
                  variant="h6"
                  component="h6">
                  {t("Metaverse List")}
                </Typography>
              </Link>
            </Grid>
          )
        }
        <Grid
          item>
          <Link className='App-link' to="/advertising">
            <Typography
              color={'secondary'}
              variant="h6"
              sx={{
                textTransform: 'uppercase'
              }}
              component="h6">
              {t('Host event')}
            </Typography>
          </Link>
        </Grid>
        <Grid
          item>
          <Link title='Frequently asked questions' className='App-link' to="/faq">
            <Typography
              sx={{
                textTransform: 'uppercase'
              }}
              color={'secondary'}
              variant="h6" component="h6">
              FAQ
            </Typography>
          </Link>
        </Grid>
        <Grid
          item>
          <Link title='Contact form' className='App-link' to="/contact">
            <Typography
              sx={{
                textTransform: 'uppercase'
              }}
              color={'secondary'}
              variant="h6" component="h6">
              {t("Contact us")}
            </Typography>
          </Link>
        </Grid>
        <Grid
          item>
          <Grid
            direction={'column'}
            alignItems={'center'}
            container>
            <Grid
              item>
              <Typography
                title='Follow us on social networks'
                color={'secondary'}
                sx={{
                  textTransform: 'uppercase'
                }}
                variant="h6"
                component="h6">
                {t("Follow us")}
              </Typography>
            </Grid>
            <Grid
              item>
              <a
                href="https://twitter.com/metavstal"
                target={'_blank'}
                rel={'noreferrer'}
                title={'Metavstal Twitter profile'}>
                <img className='social-Logo twitter-Logo' alt='Logo Twitter' src='./logo_twitter_white.svg' />
              </a>
            </Grid>
            <Grid
              item>
              <a
                href="https://discordapp.com/users/Metavstal#6513"
                target={'_blank'}
                rel={'noreferrer'}
                title={'Metavstal Discord profile'}>
                <img className='social-Logo discord-Logo' alt='Logo Discord' src='./Discord-Logo-Color.png' />
              </a>
            </Grid>
            <Grid
              item>
              <a
                href="https://linkedin.com/company/Metavstal"
                target={'_blank'} rel={'noreferrer'}
                title={'Metavstal Facebook profile'}>
                <img className='social-Logo linkedin-Logo' alt='Logo Facebook' src='./LI-In-Bug.png' />
              </a>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
      <Grid
        sx={{
          mb: 6
        }}
        justifyContent={'center'}
        direction={'row'}
        container>
        <Grid
          item >
          <Typography
            sx={{
              color: 'primary.contrastText',
              textAlign: 'center'
            }}
            variant="body2" >
            Copyright 2023. ® {t("Copyright")}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        justifyContent={'center'}
        container>
        <Grid
          item>
          <Button
            variant='contained'
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            endIcon={<ArrowDropDown></ArrowDropDown>}
          >
            {t("Languages")}
          </Button>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {
              languages.map((language, index) => (
                <MenuItem
                  key={index}

                  sx={{
                    fontSize: 12
                  }}
                  onClick={() => setLanguage(language.code)}>{t(language.name)}</MenuItem>
              ))
            }
          </Menu>
        </Grid>
      </Grid>
    </Grid>

  );
}

export default AppFooter;

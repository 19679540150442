import React, { useEffect, useState } from 'react';
import './List.css';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import MetaverseCard from '../../components/MetaverseCard/MetaverseCard';
import axios from 'axios';
import { useTranslation } from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

interface SingleObject<T> {
  id: string,
  attributes: T
}

interface Metaverse {
  Name: string,
  Description?: string,
  DateStart?: Date,
  DateEnd?: Date,
  Attendees?: number,
  ImageURL?: string,
  Link?: string
}

function List() {
  const [metaverseList, setMetaverseList] = useState<Array<SingleObject<Metaverse>>>([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation('translations');
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/metaverses?populate=*`)
      .then(({ data: { data } }) => {
        setMetaverseList(data);
        setLoading(false);
      })
  }, []);

  return (
    <Box
      component={'div'}
      sx={{
        p: 2,
        marginBottom: 5,
        marginTop: 5
      }}>
      {
        loading && (
          <Grid
            container
            flexDirection={'row'}
            justifyContent={'center'} >
            <CircularProgress
              sx={{ width: 200 }}
              size={'large'} />
          </Grid>
        )
      }
      {
        !loading && (
          <Grid
            item>
            <Typography
              color='primary'
              gutterBottom
              variant="h4"
              component="h4">
              {t("Metaverse List")}
            </Typography>
            <Grid
              container
              justifyContent={'space-around'}
              spacing={6}
              rowSpacing={5}
            >
              {
                metaverseList && metaverseList.map(({ id, attributes: { Name, ImageURL, Link } }) => (
                  <Grid
                    key={id}
                    sm={6}
                    md={4}
                    xl={4}
                    item>
                    <MetaverseCard
                      ImageURL={ImageURL}
                      Link={Link}
                      eventName={Name} />
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
        )
      }

    </Box>
  );
}

export default List;

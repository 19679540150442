import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import './EventCard.css';
import moment from 'moment';
import { useTranslation } from 'react-i18next'
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Close from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

type props = {
  eventName: string,
  startDate?: Date,
  endDate?: Date,
  attendees?: number,
  metaverse?: string,
  URL?: string,
  LinkText?: string,
  MainImageURL?: string,
  Headset?: boolean,
  Description?: string
}

function EventCard({ eventName, startDate, endDate, attendees, metaverse, URL, LinkText, MainImageURL, Headset, Description }: props) {
  const formattedStartDate = moment(startDate).format("MMM DD HH:mm")
  const formattedEndDate = moment(endDate).format("MMM DD HH:mm");
  const { t } = useTranslation('translations');
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let metaverseLogoUrl
  let defaultImage
  switch (metaverse) {
    case "DECENTRALAND":
      metaverseLogoUrl = "./assets/images/metaverses/Decentraland-logo.png";
      defaultImage = "./assets/images/metaverses/Decentraland-logo.png";
      break;
    case "VOXELS":
      metaverseLogoUrl = "./assets/images/metaverses/Voxels_logo.png";
      defaultImage = "https://www.voxels.com/images/newlogo2-final-final.svg";
      break;
    case "META_FIT_XR":
      metaverseLogoUrl = "./assets/images/metaverses/fixXR.png";
      defaultImage = "./assets/images/metaverses/fixXR.png";
      break;
    case "META_SYNTH_RIDERS":
      metaverseLogoUrl = "./assets/images/metaverses/SynthRiders.png";
      defaultImage = "./assets/images/metaverses/SynthRiders.png";
      break;
    case "META_MULTIVERSE":
      metaverseLogoUrl = "./assets/images/metaverses/Meta_lockup_mono_white_RGB.svg";
      defaultImage = "./assets/images/metaverses/Meta_lockup_mono_white_RGB.svg";
      break;
    case "SOMNIUMSPACE":
      metaverseLogoUrl = "./assets/images/metaverses/somnium_space_small.png";
      defaultImage = "./assets/images/metaverses/somnium_space_small.png";
      break;
    case "ROBLOX":
      metaverseLogoUrl = "./assets/images/metaverses/roblox_logo_white.png";
      defaultImage = "./assets/images/metaverses/roblox_logo_white.png";
      break;
    case "UPLAND":
      metaverseLogoUrl = "./assets/images/metaverses/upland-small.svg";
      defaultImage = "./assets/images/metaverses/upland-default.png";
      break;
    case "META_ORIZON_WORLDS":
      metaverseLogoUrl = "./assets/images/metaverses/Meta_lockup_mono_white_RGB.svg";
      defaultImage = "./assets/images/metaverses/Meta_lockup_mono_white_RGB.svg";
      break;
    case "META_POKERSTARS":
      metaverseLogoUrl = "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ab/PokerStars.png/210px-PokerStars.png";
      defaultImage = "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ab/PokerStars.png/210px-PokerStars.png";
      break;


  }

  return (
    <div >
      <Card
        onClick={handleOpen}
        sx={{
          mr: 0.5,
          ml: 0.5,
          height: 320,
          borderRadius: 2,
          backgroundColor: 'transparent',
          borderStyle: 'solid',
          borderColor: '#e6007a',
          borderWidth: 1
        }}>
        {
          (MainImageURL && MainImageURL === 'None') || MainImageURL === null ?
            (
              // <Card
              //   sx={{
              //     minWidth: 275,
              //     height: 150,
              //     pointerEvents: 'auto',
              //     background: "linear-gradient(160deg, rgba(40,44,52,1) 0%, rgba(63,81,181,1) 48%, rgba(210,0,112,1) 100%)"
              //   }}>
              //   <Grid
              //     sx={{
              //       height: 150
              //     }}
              //     alignContent={'center'}
              //     justifyContent={'center'}
              //     container>
              //     <Typography>
              //       {metaverse}
              //     </Typography>
              //   </Grid>
              // </Card>
              <CardMedia
                sx={{
                  objectFit:'contain'
                }}
                height={150}
                loading='lazy'
                component="img"
                image={defaultImage}
              />
            ) : (
              <CardMedia
                height={150}
                loading='lazy'
                component="img"
                image={MainImageURL}
              />
            )
        }
        <CardContent
          sx={{
            pt: 1,
            display: 'flex',
            'flexDirection': 'column',
            'alignItems': 'center',
            'justifyContent': 'center',
            'minHeight': 90
          }}
        >
          <Grid
            container
            justifyContent={'flex-end'}
            alignItems={'center'}>
            {
              Headset && (
                <Grid
                  sx={{
                    height: 0
                  }}
                  item>
                  <Tooltip title="VR Required">
                    <img
                      alt='VR Required'
                      loading='lazy'
                      style={{
                        position: 'relative',
                        bottom: 165,
                        height: 50
                      }} src={'./assets/oculus.svg'} />
                  </Tooltip>
                </Grid>
              )
            }
          </Grid>
          <Grid
            container
            justifyContent={'space-between'} >
            <Grid
              item>
              <Typography
                variant='body2'
                component={'p'}
                color="text.primary">
                {formattedStartDate && formattedStartDate.toString()}
              </Typography>
            </Grid>
            <Grid
              item>
              <Typography
                variant='body2'
                component={'p'}
                color="text.primary">
                {formattedEndDate && formattedEndDate.toString()}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            direction={'column'}>
            <Grid item>
              <Typography
                sx={{
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitLineClamp: '2',
                  WebkitBoxOrient: 'vertical',
                  lineHeight: 1.3,
                }}
                color={'white'}
                variant="subtitle1"
                component="p">
                {eventName}
              </Typography>
            </Grid>
            <Grid
              container
              direction={'row'}
              alignContent={'flex-end'}
              justifyContent={'space-between'}>
              <Typography
                component={'p'}
                color={'white'}
                fontSize={11}
              >
                {t("Attendees")}: {attendees}
              </Typography>
              {
                metaverseLogoUrl &&
                <Grid
                  sx={{
                    height: 0
                  }}
                  item>
                  <Tooltip title={`${metaverse}`}>
                    <img
                      alt="event name"
                      className='metaverse-logo-card'
                      src={metaverseLogoUrl} />
                  </Tooltip>
                </Grid>
              }
            </Grid>
          </Grid>
        </CardContent>
        {URL &&
          <CardActions>
            <Button
              sx={{
                width: '100%'
              }}
              href={URL}
              variant='contained'
              target="_blank"
              color='primary'
              size='small'>{LinkText}</Button>
          </CardActions>
        }
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <Grid
              container
              justifyContent={'flex-end'}>
              <IconButton
                onClick={handleClose}>
                <Close />
              </IconButton>
            </Grid>
          </div>
          <img
            alt={eventName}
            loading='lazy'
            style={{
              height: 150
            }}
            src={MainImageURL} />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {eventName}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {Description}
          </Typography>

          <Button
            sx={{
              width: '100%'
            }}
            href={URL}
            variant='contained'
            color='primary'
            size='small'>{LinkText}</Button>
        </Box>
      </Modal>
    </div >
  );
}

export default EventCard;

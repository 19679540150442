import { ThemeOptions } from "@mui/material";
import { blue, green, grey, lightBlue, red, yellow } from "@mui/material/colors";

export const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: 'Conthrax'
  },
  // palette: {
  //   primary: {
  //     main: '#e6007a',
  //     contrastText: '#fff',
  //   },
  //   secondary: {
  //     main: '#01c9e2',
  //   },
  //   background: {
  //     default: '#000000',
  //   },
  // },
  palette: {
    mode: 'dark',
    primary: {
      main: '#e6007a',
    },
    secondary: {
      light: '#e6007a',
      main: '#01c9e2',
      dark: '#01c9e2',
      contrastText: red[50]
    },
    error: {
      light: red[400],
      main: red[500],
      dark: red[300],
      contrastText: grey[200]
    },
    success: {
      main: green[500]
    },
    warning: {
      main: yellow[500],
      contrastText: grey[800]
    },
    info: {
      main: lightBlue[500]
    },
    text: {
      primary: '#e6007a',
      secondary: '#FFF',
      disabled: grey[900]
    },
    action: {
      active: '#e6007a',
      disabled: grey[700],
      disabledBackground: grey[200],
      hover: '#01c9e2',
      hoverOpacity: 0.7,
      focus: '#01c9e2',
      focusOpacity: 0.5,
      selected: '#e6007a',
      selectedOpacity: 0.1
    },
    background: {
      default: blue[300],
      paper: grey[200]
    },
    common: {
      black: grey[900],
      white: grey[200]
    },
    tonalOffset: 0.2
  }
};



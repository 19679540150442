import React from 'react';
import './Home.css';
import EventsBody from '../../components/EventsBody/EventsBody';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function Home() {
  return (
    <Box
      sx={{
        p: 2,
        marginBottom: 5,
        marginTop: 5
      }}
      component={'div'}>
      <Grid
        mb={5}
        container>
        <Grid
          md={6}
          container
          alignContent={'center'}
          justifyContent={'center'}
          direction={'column'}>
          <Grid
            item>
            <Typography
              gutterBottom
              color={'secondary'}
              textAlign='center'
              component="h4"
              textTransform={'uppercase'}
              variant="h4">
              Get in the metaverse!
            </Typography>
          </Grid>
          <Grid
            item>
            <Typography
              gutterBottom
              textTransform={'uppercase'}
              textAlign='center'
              color={'secondary'}
              component="h4"
              variant="h4">
              Have fun with new experiences
            </Typography>
          </Grid>
        </Grid>
        <Grid
          md={6}
          item>
          <img alt='Game on' style={{ width: '100%' }} src="./assets/images/nothing_to_see_here.jpg" />
        </Grid>
      </Grid>
      <EventsBody />
    </Box >
  );
}

export default Home;

import React from 'react';
import './AboutUs.css';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next'

function AboutUs() {
  const { t } = useTranslation('translations');

  return (
    <Box
      sx={{
        p: 2,
        marginTop: 5,
        marginBottom: 5
      }}
      component={'div'}>

      <Grid
        direction={'column'}
        alignItems={'center'}
        container>
        <Grid
          direction={'row'}
          container>
          <Grid
            lg={3}
            alignSelf={'center'}
            item>
            <Typography
              gutterBottom
              sx={{
                textTransform: 'uppercase'
              }}
              color="primary"
              component="h4"
              variant="h4">
              {t("About us")}
            </Typography>
          </Grid>
          <Grid
            lg={9}
            item>
            <Typography
              lineHeight={2.5}
              sx={{
                color: 'primary.contrastText',
                textTransform: 'uppercase'
              }}
              component="h4"
              variant="body1">
              Metavstal was born in 2022 with the idea of creating a single access point for all experiences of the metaverse.
              <br />
              The idea was to collect all the events in the metaverse, giving users the ability to choose and select their favorite ones based on their preferences.
              <br />
              We believe in the growth of the metaverse, with digital events and experiences as a fundamental part of its development.
              <br />
              We believe in giving everyone the opportunity to know the world by breaking down physical distances and geographical barriers.
              <br />
              We believe in 3D spaces as the new way to have fun, socialize, play and create communities.
              <br />
              Virtual reality is our prerogative for a new universal perspective.
            </Typography>
          </Grid>
        </Grid>
        <br />
        <br />
        <br />
        <br />
        <Grid
          direction={'row'}
          container>
          <Grid
            lg={3}
            alignSelf={'center'}
            item>
            <Typography
              sx={{
                textTransform: 'uppercase'
              }}
              color='primary'
              component="h4"
              variant="h4">
              {t("Our Values")}
            </Typography>
          </Grid>
          <Grid
            lg={9}
            item>
            <Grid
              container
              direction={{ xs: 'column', sm: 'column', md: 'row' }}
              alignItems='center'
              justifyContent={{ xs: 'center', md: 'normal' }}
              spacing={2}
            >
              <Grid
                item>
                <img
                  alt="event name"
                  className='values-Image'
                  src="./assets/about_1.jpg" />
              </Grid>
              <Grid
                alignSelf={'center'}
                item>
                <Typography
                  sx={{
                    color: 'primary.contrastText',
                    textTransform: 'uppercase'
                  }}
                  component="h6"
                  variant="body1">
                  Making people have fun.

                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction={{ xs: 'column', sm: 'column', md: 'row' }}
              alignItems='center'
              justifyContent={{ xs: 'center', md: 'normal' }}
              spacing={2}
            >
              <Grid
                item
              >
                <img
                  alt="event name"
                  className='values-Image'
                  src="./assets/about_2.jpg" />
              </Grid>
              <Grid item alignSelf={'center'}>
                <Typography
                  sx={{
                    textTransform: 'uppercase',
                    color: 'primary.contrastText',
                  }}
                  component="h6"
                  variant="body1">
                  Making metaverse accessible to everyone.
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction={{ xs: 'column', sm: 'column', md: 'row' }}
              alignItems='center'
              justifyContent={{ xs: 'center', sm: 'center', md: 'normal' }}
              spacing={2}
            >
              <Grid
                item>
                <img
                  alt="event name"
                  className='values-Image'
                  src="./assets/about_3.jpg" />

              </Grid>
              <Grid
                alignSelf={'center'}
                item>
                <Typography
                  sx={{
                    textTransform: 'uppercase',
                    color: 'primary.contrastText',
                  }}
                  component="h6"
                  variant="body1">
                  Making physical position irrelevant.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </Box>
  );
}

export default AboutUs;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './routes/Home/Home';
import AboutUs from './routes/AboutUs/AboutUs';
import Experiences from './routes/Experiences/Experiences';
import List from './routes/List/List';
import Social from './routes/Social/Social';
import AppFooter from './components/AppFooter/AppFooter';
import Grid from '@mui/material/Grid';
import NotFound from './routes/NotFound/NotFound';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import FrequentlyAskedQuestions from './routes/FrequentlyAskedQuestions/FrequentlyAskedQuestions';
import Advertising from './routes/Advertising/Advertising';
import ContactUs from './routes/ContactUs/ContactUs';
import { createTheme, ThemeProvider } from '@mui/material';
import { themeOptions } from './theme/theme';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const theme = createTheme(themeOptions);

root.render(
  <I18nextProvider i18n={i18n}>
    <ThemeProvider theme={theme}>

      <BrowserRouter>
        <Grid
          direction={'row'}
          container
          justifyContent={'center'}
        >
          <Grid
            item
            lg={10}
            xl={10}
            md={11}
          >
            <App />
            <Routes>
              <Route path="*" element={<NotFound />} />
              <Route path="/" element={<Home />} />
              <Route path="home" element={<Home />} />
              <Route path="about" element={<AboutUs />} />
              <Route path="experiences" element={<Experiences />} />
              <Route path="social" element={<Social />} />
              <Route path="list" element={<List />} />
              <Route path="faq" element={<FrequentlyAskedQuestions />} />
              <Route path="advertising" element={<Advertising />} />
              <Route path="contact" element={<ContactUs />} />
            </Routes>
            <AppFooter></AppFooter>
          </Grid>
        </Grid>
      </BrowserRouter>
    </ThemeProvider>
  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

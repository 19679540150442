import React from 'react';
import './NavBar.css';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next'

function NavBar() {
  const { t } = useTranslation('translations');

  return (
    <header >
      <nav >
        <Grid
          container
          spacing={1}
          justifyContent='space-evenly'
          alignItems={'center'}
          direction={'row'}>

          <Grid item xs={6} md={1} lg={2}>
            <Link className='App-link' to="/">
              <img src="./assets/default-monochrome.svg" alt="" />
            </Link>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>

          </Grid>

          <Grid
            xs={12}
            sm={'auto'}
            md={'auto'}
            lg={'auto'}
            item>
            <Link className='App-link' to="/about">
              <Typography
                sx={{
                  textTransform: 'uppercase'
                }}
                color={'secondary'}
                variant="h6" component="h6">
                {t("About us")}
              </Typography>
            </Link>
          </Grid>

          <Grid
            xs={12}
            sm={'auto'}
            md={'auto'}
            lg={'auto'}
            item>
            <Link className='App-link' to="/advertising">
              <Typography
                sx={{
                  textTransform: 'uppercase'
                }}
                color={'secondary'}
                variant="h6" component="h6">
                {t('Host event')}
              </Typography>
            </Link>
          </Grid>

          <Grid
            item
            sm={'auto'}
            md={'auto'}
            lg={'auto'}
            xs={12}>
            <Link className='App-link' to="/list">
              <Typography
                sx={{
                  textTransform: 'uppercase'
                }}
                color={'secondary'}
                variant="h6" component="h6">
                {t("Metaverses")}
              </Typography>
            </Link>
          </Grid>
          {/* {
            shouldHideSocialNetwork && (
              <Grid
                item>
                <img className='social-Logo twitter-Logo' alt='Logo Twitter' src='./logo_twitter_white.svg' />
              </Grid>
            )
          }
          {
            shouldHideSocialNetwork && (
              <Grid item>
                <img className='social-Logo discord-Logo' alt='Logo Discord' src='./Discord-Logo-Color.png' />
              </Grid>
            )
          }
          {
            shouldHideSocialNetwork && (
              <Grid item>
                <img className='social-Logo linkedin-Logo' alt='Logo Instagram' src='./LI-In-Bug.png' />
              </Grid>
            )
          } */}

        </Grid>
      </nav>
    </header >
  );
}

export default NavBar;

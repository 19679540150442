import React from 'react';
import './Social.css';
import Typography from '@mui/material/Typography';

function Social() {
  return (
    <div >
      <Typography variant="h1" component="h3">
        Social
      </Typography>
    </div>
  );
}

export default Social;

import React from 'react';
import './FrequentlyAskedQuestions.css';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type Question = {
  title: string,
  text: string
}

function FrequentlyAskedQuestions() {
  const { t } = useTranslation('translations');
  const questions: Array<Question> = [
    {
      title: 'What is the metaverse?',
      text: 'A virtual-reality space in which users can interact with a computer-generated environment and other users.'
    },
    {
      title: 'What is Metavstal.com?',
      text: 'Metavstal.com collects all the events available in the metaverse and provides a single access point for the users'
    },
    {
      title: 'Which metaverse are available on metavstal.com',
      text: 'Metavstal.com collects the events / experiences of the following metaverse: decentraland, Somniumspace, Voxels, Meta Orizon Venus, Meta Multiverse, Meta Synth Riders, Meta Fit XR, Upland, Roblox.In the future others metaverse will be available.'
    },
    {
      title: 'What events are available ?',
      text: 'All the events / experiences in the metaverse are available on metavstal.com.In general, social, gaming, fitness, music, party, sports events / experiences'
    }
    ,
    {
      title: 'How are the events ordered ?',
      text: 'Three section are available: Today with the events available during the day, Coming Soon with the events available in the next two days and Next Days with the events accessible in three days onwards'
    },
    {
      title: 'How to access an event ?',
      text: 'After choosing the event to access it just select the GO! button.'
    },
    {
      title: 'What is the time zone of the events ?',
      text: 'The time zone is indicated in the event card'
    }, {
      title: 'How to search for an event ?',
      text: 'To find a specific event, it is possible to filter a date, a metaverse, a category or the need for a device'
    },
    {
      title: 'Is it free to access an event?',
      text: 'All the events/experience in metavstal.com are free. The metaverse could apply a cost to access to the event'
    },
    {
      title: 'Are VR devices required to access events?',
      text: 'To attend some events a handset (Oculus) is required. Metavsta.com indicates the events where and handset are needed'
    }]

  return (
    <Box
      sx={{
        p: 2,
        marginTop: 5,
        marginBottom: 5
      }}
      component={'div'}>
      <Typography
        sx={{
          color: 'primary.main'
        }}
        gutterBottom
        variant="h4"
        component="h4">
        {t("Frequently Asked Questions")}
      </Typography>
      <div>
        {
          questions.map(({ title, text }, index) => (
            <Accordion
              sx={
                {
                  backgroundColor: 'transparent'
                }
              }
              key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography color="secondary"> {text} </Typography>
              </AccordionDetails>
            </Accordion>
          ))
        }
      </div>
    </Box>
  );
}

export default FrequentlyAskedQuestions;

import React from 'react';
import './App.css';

import NavBar from './components/NavBar/NavBar';


function App() {
  return (
      <div className="App">
        <NavBar />
      </div>
  );
}

export default App;

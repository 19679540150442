import React from 'react';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import './MetaverseCard.css';

type props = {
  eventName: string,
  description?: string,
  ImageURL?: string,
  Link?: string
}

function MetaverseCard({ eventName, ImageURL, Link }: props) {
  return (
    <div>
      <a
        style={{
          textDecoration: 'none'
        }}
        title={eventName}
        target={'_blank'}
        rel="noreferrer"
        href={Link}>
        <Card sx={{
          minWidth: 275,
          background: "linear-gradient(160deg, rgba(40,44,52,1) 0%, rgba(63,81,181,1) 48%, rgba(210,0,112,1) 100%)"
        }}>
          <CardContent>
            <Grid
              container
              direction={'column'}>
              <Grid item>
                <Typography
                  gutterBottom
                  sx={{
                    color: 'primary.contrastText',
                  }}
                  variant="h6" component="h6">
                  {eventName}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              justifyContent={'center'}
              container>
              <img
                style={{
                  height: 200,
                  objectFit: "contain"
                }}
                alt={eventName}
                className='main-event-image'
                src={ImageURL || './assets/images/game_on.jpg'} />
            </Grid>

          </CardContent>
        </Card>
      </a>
    </div>
  );
}

export default MetaverseCard;
